<template>
    <a-drawer
        v-model:visible="visible"
        :closable="false"
        :maskClosable="false"
        title="检具详情"
        height="100%"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-descriptions title="" :column="2">
            <a-descriptions-item label="检具编码">{{ infoData?.code }}</a-descriptions-item>
            <a-descriptions-item label="检具名称">{{ infoData?.name }}</a-descriptions-item>
            <a-descriptions-item label="检具状态">
                {{ infoData?.checkToolStatusZh }}
            </a-descriptions-item>
            <a-descriptions-item label="检具类别">{{ infoData?.categoryName }}</a-descriptions-item>
            <a-descriptions-item label="规格型号">{{ infoData?.spec }}</a-descriptions-item>
            <a-descriptions-item label="品牌">{{ infoData?.brand }}</a-descriptions-item>
            <a-descriptions-item label="出厂编号">
                {{ infoData?.factoryNumber }}
            </a-descriptions-item>
            <a-descriptions-item label="负责人">
                {{ infoData?.responsiblePersonDisplayName }}
            </a-descriptions-item>
            <a-descriptions-item label="现存数量">
                {{ infoData?.quantity }} {{ infoData?.quantityUomUnitName }}
            </a-descriptions-item>
            <a-descriptions-item label="校准周期">
                {{ infoData?.calibrationInfo?.calibrationPeriod }}
                {{ infoData?.calibrationInfo?.calibrationPeriodUomUnitName }}
            </a-descriptions-item>
            <a-descriptions-item label="有效期">
                {{ infoData?.validPeriodInfo?.validPeriod }}
                {{ infoData?.validPeriodInfo?.validPeriodUomUnitName }}
            </a-descriptions-item>
            <a-descriptions-item label="最新校准日期" :span="2">
                {{ infoData?.latestCalibrationDate }}
            </a-descriptions-item>
            <a-descriptions-item label="备注" :span="2">
                {{ infoData?.remark }}
            </a-descriptions-item>
            <a-descriptions-item label="检具附件" :span="2">
                <div class="filebox">
                    <a
                        v-for="item in infoData?.checkToolFiles"
                        :href="`${fileURL}/download/${item?.id}`"
                    >
                        {{ item?.fileName }}
                    </a>
                </div>
            </a-descriptions-item>
            <a-descriptions-item label="创建日期">{{ infoData?.createTime }}</a-descriptions-item>
            <a-descriptions-item label="创建人">
                {{ infoData?.creatorDisplayName }}
            </a-descriptions-item>
            <a-descriptions-item label="上次变更时间">
                {{ infoData?.updateTime }}
            </a-descriptions-item>
            <a-descriptions-item label="变更人">
                {{ infoData?.modifierDisplayName }}
            </a-descriptions-item>
        </a-descriptions>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs } from "vue";
import { apiCheckToolDetail } from "@/api/qualityCheck";
import { fileURL } from "@/utils/config";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
    },
    {
        title: "工序名称",
        dataIndex: "name",
    },
    {
        title: "工序编码",
        dataIndex: "code",
    },
    {
        title: "工序类型",
        dataIndex: "typeDesc",
    },
    {
        title: "工序类别",
        dataIndex: "categoryName",
    },
    {
        title: "质检要求",
        dataIndex: "needDetect",
    },
    {
        title: "工序附件",
        dataIndex: "stepFileIds",
    },
];

export default defineComponent({
    props: ["detailId"],
    emits: ["update:detailId", "success"],
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            loading: false,
            infoData: null,
        });

        const handleCancel = () => {
            emit("update:detailId");
        };

        const getSetIndexData = async id => {
            let res = await apiCheckToolDetail(id);
            if (res.status === "SUCCESS") {
                state.infoData = res?.data;
            }
        };

        watch(
            () => props.detailId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getSetIndexData(newVal);
                    state.savePermList = [];
                }
            }
        );

        return {
            fileURL,
            columns,
            ...toRefs(state),
            handleCancel,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.filebox {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 10px;
    }
}
</style>
