<template>
    <CheckToolCategory
        :data="checkToolCategoryList"
        v-model:categoryVisible="checkToolCategoryVisible"
        typeName="检具"
        @onSelect="onChangeCheckToolCategory"
    />
    <UserList
        v-model:userListVisible="userListVisible"
        @onSelect="onSelectUser"
        selectType="radio"
    />
    <a-modal
        v-model:visible="visible"
        :title="`${upDateId ? '编辑' : '新增'}检具`"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="700px"
    >
        <a-form
            ref="formRef"
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-row :span="24" class="margin-bottom">
                <a-col :span="24">
                    <a-form-item
                        label="检具编码"
                        name="code"
                        :rules="[
                            { required: true, message: '请输入检具编码' },
                            { validator: checkAllSpace },
                        ]"
                        style="margin-bottom: 20px"
                    >
                        <a-form-item>
                            <div class="contant-item">
                                <a-input
                                    style="width: 76%; margin-right: 20px"
                                    :disabled="sysCreate || Boolean(upDateId)"
                                    v-model:value="formData.code"
                                    :maxlength="100"
                                    showCount
                                ></a-input>
                                <a-checkbox
                                    :disabled="Boolean(upDateId)"
                                    v-model:checked="sysCreate"
                                    v-if="!upDateId"
                                >
                                    系统生成
                                </a-checkbox>
                            </div>
                        </a-form-item>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-form-item
                label="检具名称"
                name="name"
                :rules="[
                    { required: true, message: '请输入检具名称' },
                    { validator: checkAllSpace },
                ]"
            >
                <a-input v-model:value="formData.name" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item label="检具类别" name="categoryName">
                <a-form-item>
                    <SelectInput
                        :title="formData.categoryName"
                        :onClick="() => (checkToolCategoryVisible = true)"
                        @onDelete="() => onChangeCheckToolCategory()"
                    ></SelectInput>
                </a-form-item>
            </a-form-item>

            <a-form-item
                label="检具状态"
                name="toolStatus"
                :rules="[
                    { required: true, message: '请选择检具状态' },
                    { validator: checkAllSpace },
                ]"
            >
                <Select
                    v-model:value="formData.toolStatus"
                    :options="toolStatusOptions"
                    labelKey="name"
                    valueKey="code"
                />
            </a-form-item>

            <a-form-item label="规格型号" name="spec" :rules="[{ validator: checkAllSpace }]">
                <a-input v-model:value="formData.spec" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item label="品牌" name="brand" :rules="[{ validator: checkAllSpace }]">
                <a-input v-model:value="formData.brand" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item
                label="出厂编号"
                name="factoryNumber"
                :rules="[{ validator: checkAllSpace }]"
            >
                <a-input v-model:value="formData.factoryNumber" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item
                label="负责人"
                name="responsiblePersonDisplayName"
                :rules="[{ required: true, message: '请选择负责人' }]"
            >
                <SelectInput
                    :title="formData.responsiblePersonDisplayName"
                    :onClick="() => (userListVisible = true)"
                    :showDeleteIcon="false"
                ></SelectInput>
            </a-form-item>

            <a-form-item label="现存数量" name="quantityInfo" autoLink>
                <a-form-item>
                    <div class="contant-item">
                        <a-input-number
                            style="width: 380px"
                            v-model:value="formData.quantity"
                            :min="0"
                            :precision="0"
                            :maxlength="9"
                            showCount
                        />
                        <Select
                            style="width: 80px"
                            :allowClear="false"
                            v-model:value="formData.quantityUomUnit"
                            :options="countUnitOptions"
                            :labelInValue="true"
                            labelKey="name"
                            valueKey="code"
                        />
                    </div>
                </a-form-item>
            </a-form-item>

            <a-form-item name="calibrationInfo" :rules="[{ validator: checkCalibrationPeriod }]">
                <template v-slot:label>
                    <span class="require-star">*</span>
                    校准周期
                </template>
                <a-form-item>
                    <div class="contant-item">
                        <a-input-number
                            style="width: 380px"
                            v-model:value="formData.calibrationPeriod"
                            :min="0"
                            :precision="0"
                            :maxlength="9"
                            showCount
                        />
                        <Select
                            style="width: 80px"
                            :allowClear="false"
                            v-model:value="formData.calibrationPeriodUomUnit"
                            :options="dataUnitOptions"
                            :labelInValue="true"
                            labelKey="name"
                            valueKey="code"
                        />
                    </div>
                </a-form-item>
            </a-form-item>

            <a-form-item
                label="最新校准日期"
                name="latestCalibrationDate"
                :rules="[{ required: true, message: '请输入最新校准日期' }]"
            >
                <a-date-picker
                    v-model:value="formData.latestCalibrationDate"
                    valueFormat="YYYY-MM-DD"
                    style="width: 100%"
                />
            </a-form-item>

            <a-form-item name="validPeriodInfo">
                <template v-slot:label>有效期</template>
                <a-form-item>
                    <div class="contant-item">
                        <a-input-number
                            style="width: 380px"
                            v-model:value="formData.validPeriod"
                            :min="0"
                            :precision="0"
                            :maxlength="9"
                            showCount
                        />
                        <Select
                            style="width: 80px"
                            :allowClear="false"
                            v-model:value="formData.validPeriodUomUnit"
                            :options="dataUnitOptions"
                            :labelInValue="true"
                            labelKey="name"
                            valueKey="code"
                        />
                    </div>
                </a-form-item>
            </a-form-item>

            <a-form-item label="备注" name="remark" :rules="[{ validator: checkAllSpace }]">
                <a-textarea
                    v-model:value="formData.remark"
                    placeholder="最多可输入800字"
                    :rows="4"
                    :maxlength="800"
                />
            </a-form-item>

            <a-form-item label="检具附件" name="checkToolFiles">
                <UploadFile
                    businessType="BAS_CHECK_TOOL"
                    :files="formData.checkToolFiles"
                    @onSuccess="fileInfo => onSuccess(fileInfo)"
                    @deleteFile="index => onDeleteFile(index)"
                ></UploadFile>
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 18, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiCheckToolUpdate, apiCheckToolUpdateIndexData } from "@/api/qualityCheck";
import Select from "@/components/Select";
import CheckToolCategory from "@/components/CategoryModal";
import { showMessage, checkValueIsAvailable, getAutoCreateCode } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import UploadFile from "@/components/UploadFile";
import SelectInput from "@/components/SelectInput";
import UserList from "@/components/UserList";
export default defineComponent({
    props: ["upDateId"],
    emits: ["update:upDateId", "onUpdateSuccess"],
    components: {
        Select,
        CheckToolCategory,
        DownOutlined,
        UploadFile,
        SelectInput,
        Select,
        UserList,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            sysCreate: false,
            formRef: null,
            userListVisible: false,
            countUnitOptions: [],
            dataUnitOptions: [],
            toolStatusOptions: [],
            processTypeOptions: [],
            checkToolCategoryList: [],
            checkToolCategoryVisible: false,
            formData: {
                id: "",
                name: "",
                code: "",
                categoryCode: "",
                categoryName: "",
                toolStatus: "",
                spec: "",
                brand: "",
                factoryNumber: "",
                responsiblePersonUsername: "",
                responsiblePersonDisplayName: "",
                quantityInfo: "",
                quantity: undefined,
                quantityUomUnit: undefined,
                validPeriodInfo: undefined,
                validPeriod: undefined,
                validPeriodUomUnit: undefined,
                calibrationInfo: undefined,
                calibrationPeriod: undefined,
                calibrationPeriodUomUnit: undefined,
                latestCalibrationDate: "",
                checkToolFiles: [],
            },
        });

        const handleCancel = () => {
            state.formRef.resetFields();
            state.visible = false;
            state.formData.id = "";
            state.sysCreate = false;
            state.autoCreateCode = "";
            emit("update:upDateId", null);
        };

        const onFinish = async () => {
            const {
                calibrationPeriod,
                calibrationPeriodUomUnit: {
                    label: calibrationPeriodUomUnitName,
                    value: calibrationPeriodUomUnitCode,
                },
                validPeriod,
                validPeriodUomUnit: {
                    label: validPeriodUomUnitName,
                    value: validPeriodUomUnitCode,
                },
                quantityUomUnit: { label: quantityUomUnitName, value: quantityUomUnitCode },
            } = state.formData;
            let checkToolFileIds = state.formData.checkToolFiles.map(item => item?.id);
            const reqData = {
                ...state.formData,
                checkToolFileIds,
                quantityUomUnitCode,
                quantityUomUnitName,
                calibrationInfo: {
                    calibrationPeriod,
                    calibrationPeriodUomUnitCode,
                    calibrationPeriodUomUnitName,
                },
                validPeriodInfo: {
                    validPeriod,
                    validPeriodUomUnitName,
                    validPeriodUomUnitCode,
                },
            };
            let res = await apiCheckToolUpdate(reqData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("onUpdateSuccess");
            }
        };

        const getIndexData = async id => {
            let res = await apiCheckToolUpdateIndexData(id);
            if (res.status === "SUCCESS") {
                const {
                    toolStatuses,
                    primaryUomCandidate,
                    categoryCandidate,
                    dateUomCandidate,
                    checkTool,
                } = res?.data;
                state.toolStatusOptions = toolStatuses;
                state.countUnitOptions = primaryUomCandidate;
                state.dataUnitOptions = dateUomCandidate;
                state.checkToolCategoryList = categoryCandidate;
                if (checkTool) {
                    Object.keys(state.formData).forEach(key => {
                        state.formData[key] = checkTool ? checkTool?.[key] : undefined;
                    });
                    state.formData.quantityUomUnit = {
                        label: checkTool?.quantityUomUnitName,
                        value: checkTool?.quantityUomUnitCode,
                    };
                    state.formData.calibrationPeriod =
                        checkTool?.calibrationInfo?.calibrationPeriod;
                    state.formData.calibrationPeriodUomUnit = {
                        label: checkTool?.calibrationInfo?.calibrationPeriodUomUnitName,
                        value: checkTool?.calibrationInfo?.calibrationPeriodUomUnitCode,
                    };
                    state.formData.validPeriod = checkTool?.validPeriodInfo?.validPeriod;
                    state.formData.validPeriodUomUnit = {
                        label: checkTool?.validPeriodInfo?.validPeriodUomUnitName,
                        value: checkTool?.validPeriodInfo?.validPeriodUomUnitCode,
                    };
                } else {
                    state.formData.quantity = undefined;
                    state.formData.quantityUomUnit = {
                        label: primaryUomCandidate?.[0]?.name,
                        value: primaryUomCandidate?.[0]?.code,
                    };
                    state.formData.calibrationPeriod = undefined;
                    state.formData.calibrationPeriodUomUnit = {
                        label: dateUomCandidate?.[0]?.name,
                        value: dateUomCandidate?.[0]?.code,
                    };
                    state.formData.validPeriod = undefined;
                    state.formData.validPeriodUomUnit = {
                        label: dateUomCandidate?.[0]?.name,
                        value: dateUomCandidate?.[0]?.code,
                    };
                }
            }
        };

        const checkAllSpace = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const onChangeCheckToolCategory = arr => {
            const [value] = arr || [];
            state.formData.categoryName = value ? value?.name : "";
            state.formData.categoryCode = value ? value?.code : "";
        };

        const onSuccess = fileInfo => {
            state.formData.checkToolFiles = [...state.formData.checkToolFiles, fileInfo];
        };

        const onDeleteFile = index => {
            state.formData.checkToolFiles.splice(index, 1);
        };

        const onSelectUser = ([user]) => {
            state.formData.responsiblePersonUsername = user?.username;
            state.formData.responsiblePersonDisplayName = user?.displayName;
        };

        const checkCalibrationPeriod = (rule, value) => {
            const { calibrationPeriod, calibrationPeriodUomUnit } = state.formData;
            if (calibrationPeriod == undefined || calibrationPeriodUomUnit == undefined) {
                return Promise.reject("请输入校准周期");
            }
            return Promise.resolve();
        };

        const getOrder = async () => {
            let code = await getAutoCreateCode("BAS_CHECK_TOOL");
            state.autoCreateCode = code;
            state.formData.code = code;
        };

        watch(
            () => state.sysCreate,
            newVal => {
                if (newVal && !state.autoCreateCode) {
                    getOrder();
                } else {
                    state.formData.code = state.autoCreateCode;
                }
                if (!newVal) state.formData.code = "";
            }
        );

        watch(
            () => props.upDateId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    state.category = "";
                    getIndexData(newVal);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkAllSpace,
            onChangeCheckToolCategory,
            onSuccess,
            onDeleteFile,
            onSelectUser,
            checkCalibrationPeriod,
        };
    },
});
</script>

<style lang="less" scoped>
.contant-item {
    display: flex;
    justify-content: space-between;
}
.require-star {
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
}
</style>
